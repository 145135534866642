define( [
	"./arr"
], function( arr ) {

"use strict";

// Support: IE 9 - 11+, Edge 18+, Android Browser 4.0 - 4.3 only, iOS 7 - 11 only, Safari 11 only,
// Firefox <= 61 only
// Provide fallback for browsers without Array#flat.
return arr.flat ? function( array ) {
	return arr.flat.call( array );
} : function( array ) {
	return arr.concat.apply( [], array );
};

} );
